import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../components/layout"
import Header from "../components/header"
import Img, { FluidObject } from "gatsby-image"
import Container from "react-bootstrap/Container"
import { ParallaxBanner } from "react-scroll-parallax/cjs"
import { ImageItem, Project } from "../pages/homepage/projects-section"
import "./project-gallery.scss"
import CardColumns from "react-bootstrap/CardColumns"
import Card from "react-bootstrap/Card"
import Modal from "react-bootstrap/Modal"
import Carousel from "react-bootstrap/Carousel"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons"

interface Props {
  data: {
    projectsYaml: Project
    images: { edges: ImageItem[] }
  }
}

const ProjectGallery = ({ data }: Props) => {
  const { title, project, image, description, testimonial } = data.projectsYaml

  const headerImagePathRegex = new RegExp(`images/projects/${project}`)
  const imageIndex = data.images.edges.findIndex(
    (x: ImageItem) =>
      x.node.name ===
        image
          .split(".")
          .slice(0, -1)
          .join(".") && x.node.dir.match(headerImagePathRegex)
  )

  const galleryImages = data.images.edges
    .filter((x: ImageItem) => {
      const matchFound = x.node.dir.match(`${project}/gallery`)
      return matchFound && matchFound.length > 0
    })
    .sort((a, b) => {
      if (a.node.name < b.node.name) {
        return -1
      }
      if (a.node.name > b.node.name) {
        return 1
      }

      return 0
    })

  const renderDescription = () => {
    return description.map((para: string, index: number) => (
      <p key={`project-description-${index}`}>{para}</p>
    ))
  }

  const renderTestimonial = () => {
    return testimonial.map(
      (para: string, index: number) => para
      // <p key={`project-testimonial-${index}`}>{para}</p>
      // ({ para })
    )
  }

  const [carouselVisible, setCarouselVisible] = useState(false)
  const [index, setIndex] = useState(0)
  const showCarousel = (initialIndex: number) => {
    setIndex(initialIndex)
    setCarouselVisible(true)
  }
  const hideCarousel = () => setCarouselVisible(false)

  const renderThumbnails = () => {
    return galleryImages.map(
      (
        image: { node: { childImageSharp: { fluid: FluidObject } } },
        index: number
      ) => {
        const { fluid } = image.node.childImageSharp
        return (
          <Card
            className="bg-dark"
            key={`gallery-image-${index}`}
            onClick={() => showCarousel(index)}
          >
            <Card.Body>
              <Img fluid={fluid} className="card-img" />
            </Card.Body>
          </Card>
        )
      }
    )
  }

  const renderCarouselImages = () => {
    return galleryImages.map(
      (
        image: { node: { childImageSharp: { fluid: FluidObject } } },
        index: number
      ) => {
        const { fluid } = image.node.childImageSharp
        return (
          <Carousel.Item key={`carousel-image-${index}`}>
            <Img fluid={fluid} className="d-block w-100" />
          </Carousel.Item>
        )
      }
    )
  }

  return (
    <Layout className={"gallery-page"} hidePhoneNumber>
      <SEO title={title} />
      <Header fixedOnLoad />
      <Container fluid>
        <Row>
          <ParallaxBanner
            style={{ height: "35vw" }}
            layers={[
              {
                amount: 0.3,
                children: (
                  <Img
                    fluid={
                      data.images.edges[imageIndex].node.childImageSharp.fluid
                    }
                  />
                ),
              },
              {
                amount: 0,
                children: (
                  <div
                    className="parallax-layer-container"
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h1 className="gallery-page-title">{title}</h1>
                  </div>
                ),
              },
            ]}
          />
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs={{ span: 12 }} lg={{ span: 6 }} className="mt-4 mb-4">
            <div className="gallery-description">{renderDescription()}</div>
          </Col>
          <Col xs={{ span: 12 }} lg={{ span: 6 }} className="mb-4 mt-lg-4">
            <div className="gallery-testimonial">
              <FontAwesomeIcon icon={faQuoteLeft} className="d-inline-block mr-1" />
              {renderTestimonial()}
              <FontAwesomeIcon icon={faQuoteRight} className="d-inline-block ml-1" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <CardColumns>{renderThumbnails()}</CardColumns>
          </Col>
          <Modal show={carouselVisible} onHide={hideCarousel} size="lg">
            <Modal.Body>
              <Carousel activeIndex={index} onSelect={setIndex}>
                {renderCarouselImages()}
              </Carousel>
            </Modal.Body>
          </Modal>
        </Row>
        <Row>
          <Col>
            <AnchorLink
              to={`/#projects`}
              className="gallery-back-link text-center"
            >
              <span>
                <FontAwesomeIcon icon={faChevronLeft} /> Return to Projects
              </span>
            </AnchorLink>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($project: String!) {
    projectsYaml(project: { eq: $project }) {
      title
      project
      image
      description
      testimonial
    }
    images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      edges {
        node {
          name
          dir
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ProjectGallery
